<template>
  <div class="fixed bg-grey-6" style="z-index: 1000; width: 100%; top: 0px; left: 0px;">
    <div class=" q-py-lg" style=" position: relative; display: block; height: 100vh; overflow: auto; margin: 0 auto;">
      <div class="page" style="padding: 15mm 15mm 15mm 15mm;"  ref="content1">
        <div class="row justify-end">
          <q-field dense square outlined style="width: 250px;">
            <template v-slot:control>
              <div class="self-center text-bold full-width " tabindex="0">NO. FORMULIR</div>
            </template>
          </q-field>
        </div>
        <q-card flat bordered class="q-pa-lg q-my-lg" >
          <q-card-section>
            <div style="text-align: center; position: relative;">
              <div style="position: absolute; left: -20px;">
                <img src="@/assets/img/Logo4.png" alt="logo_afbs" style="width: 64px; width: 64px;" />
              </div>
              <div style="position: absolute; right: -20px;">
                <img src="@/assets/img/Logo5.png" alt="logo_afbs" style="width: 64px; width: 64px;" />
              </div>
              <div class="text-weight-bold" style="font-size: 14pt;">YAYASAN DAR AL FURQON AL HAKIM <br /></div>
              <div class="text-h5 text-weight-bold">
                SMP AL FURQON BOARDING SCHOOL
              </div>
              <div class=" text-weight-bold"  style="font-size: 12pt;">
                PANITIA PENERIMAAN SISWA/I BARU
              </div>
              <div class=" text-weight-bold">
                TAHUN PELAJARAN 2021-2022
              </div>
            </div>
          </q-card-section>
          <q-card-section :class="blur">
            <table class="text-bold">
              <tr>
                <td class="text-h6 text-bold" style="width: 250px;">NAMA CALON SISWA/I</td>
                <td class="text-h6 text-bold" >: {{data.nama}}</td>
              </tr>
              <tr>
                <td class="text-h6 text-bold" >ALAMAT</td>
                <td>: {{data.alamat}}</td>
              </tr>
              <tr>
                <td></td>
                <td>Kel. {{data.kelurahan}}</td>
              </tr>
              <tr>
                <td></td>
                <td>Kec. {{data.kecamatan}}</td>
              </tr>
              <tr>
                <td></td>
                <td>Kota/Kab. {{data.kab_kota}}</td>
              </tr>
              <tr>
                <td></td>
                <td>Prov. {{data.provinsi}}</td>
              </tr>
            </table>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-lg q-my-lg">
          <q-card-section>
            <div class="text-h6 text-center text-bold" style="text-decoration: underline;">
              KELENGKAPAN ADMINISTRASI
            </div>
            <table class="kelengkapan"  :class="blur">
              <tr>
                <td><div class="box"></div></td>
                <td>Formulir pendaftaran yang telah diisi lengkap</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Foto copy KTP orang tua/wali (1 lembar)</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Pas foto berwarna latar belakang merah 3x4 : 2 lembar <br/>
                    (putra berkemeja putih berdasi &amp; putri berjilbab putih)</td>
              </tr>
              <tr>
                <td><div class="box"></div> </td>
                <td>Foto copy akte kelahiran (2 lembar)</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Foto copy Kartu Keluarga (KK) (2 lembar)</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Bukti biaya pendaftaran</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Foto copy STTB/Ijazah SD.MI yang dilegalisir (2 lembar)</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Foto copy SKHUN (2 lembar)</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Surat pernyataan orang tua/Wali</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Foto copy raport SD kelas I - VI</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Surat keterangan sehat dari dokter</td>
              </tr>
              <tr>
                <td><div class="box"></div></td>
                <td>Melunasi biaya pendidikan (perincian terlampir)*</td>
              </tr>
            </table>
          </q-card-section>
        </q-card>
      </div>
      <div class="page" ref="content2">
        <div style="text-align: center; position: relative;">
          <div style="position: absolute; left: -20px;">
            <img src="@/assets/img/Logo4.png" alt="logo_afbs" style="width: 64px; width: 64px;" />
          </div>
          <div style="position: absolute; right: -20px;">
            <img src="@/assets/img/Logo5.png" alt="logo_afbs" style="width: 64px; width: 64px;" />
          </div>
          <div class="text-weight-bold" style="font-size: 14pt;">YAYASAN DAR AL FURQON AL HAKIM <br /></div>
          <div class="text-h5 text-weight-bold">
            SMP AL FURQON BOARDING SCHOOL
          </div>
          <div class=" text-weight-bold"  style="font-size: 12pt;">
            PANITIA PENERIMAAN SISWA/I BARU
          </div>
          <div class=" text-weight-bold">
            TAHUN PELAJARAN 2021-2022
          </div>
          <div style="border-bottom: 3px double black; font-size: 9pt;">
            Jl Untung Suropati No.13, Kel. Cimone Jaya, Kec. Karawaci, Kota Tangerang
          </div>
        </div>
        <br/>
        <div class="text-center" style="font-weight: bold !important;text-decoration: underline;">
          FORMULIR PENDAFTARAN
        </div>
        <div :class="blur">
          <!-- <div class="text-bold q-mt-md">
            STATUS BAYAR: {{ !data.file_transfer ? "BELUM LUNAS" : data.status == "Y" ? "LUNAS" : "DALAM VERIFIKASI" }}
          </div> -->
          <div class="text-bold q-mt-xl" style="text-decoration: underline;">
            DATA PRIBADI SISWA/I
          </div>
          <div>
            <table>
              <tr>
                <td>Nama Lengkap</td>
                <td>: {{ data.nama }}</td>
              </tr>
              <tr>
                <td>Nama Panggilan</td>
                <td>: {{ data.alias }}</td>
              </tr>
              <tr>
                <td>Tempat &amp; Tanggal Lahir</td>
                <td>: {{ data.temp_lahir }}, {{ data.tgl_lahir |moment("DD-MMMM-YYYY")}}</td>
              </tr>
              <tr>
                <td>Jenis Kelamin</td>
                <td v-if="data.jenis_kelamin=='L'">: Laki-laki</td>
                <td v-else>: Perempuan</td>
              </tr>
              <tr>
                <td>Anak Ke</td>
                <td>: {{ data.anak_ke }} dari {{ parseInt(data.saudara_kandung) + parseInt(data.saudara_tiri) + 1 }} bersaudara ({{parseInt(data.saudara_kandung)}} kandung, {{parseInt(data.saudara_tiri)}} tiri) </td>
              </tr>
              <tr>
                <td>Alamat</td>
                <td>
                  : {{ data.alamat }},
                </td>
              </tr>
              <tr>
                <td></td>
                <td style="padding-left: 1em">RT/RW: {{ data.rt_rw }},Kel. {{ data.kelurahan }}, Kec. {{ data.kecamatan }},</td>
              </tr>
              <tr>
                <td></td>
                <td style="padding-left: 1em">{{ data.kab_kota }}, {{ data.provinsi }}, {{ data.kode_pos }}</td>
              </tr>
              <tr>
                <td>Kewarganegaraan</td>
                <td>: {{ data.nation }}</td>
              </tr>
            </table>
          </div>
          <div class="text-bold q-mt-md" style="text-decoration: underline;">
            IDENTITAS ORANG TUA
          </div>
          <div>
            <table>
              <tr>
                 <td class="text-weight-bold">Nama Ayah</td> 
                <td>: {{ data.nama_ayah }}</td>
              </tr>
              <tr>
                <td>Tempat &amp; Tanggal Lahir</td>
                <td>: {{ data.temp_lahir_ayah }}, {{ data.tgl_lahir_ayah |moment("DD-MMMM-YYYY")}}</td>
              </tr>
              <tr>
                <td>Pendidikan Terakhir</td>
                <td>: {{ data.pend_ayah }}</td>
              </tr>
              <tr>
                <td>Pekerjaan</td>
                <td>: {{ data.kerja_ayah }}</td>
              </tr>
              <tr>
                <td>Kewarganegaraan</td>
                <td>: {{ data.nation_ayah }}</td>
              </tr>
              <tr>
                <td>No Telp./HP</td>
                <td>: {{ data.no_hp_ayah }}</td>
              </tr>
            </table>
            <br/>
            <table>
              <tr>
                <td class="text-weight-bold">Nama Ibu</td>
                <td>: {{ data.nama_ibu }}</td>
              </tr>
              <tr>
                <td>Tempat &amp; Tanggal Lahir</td>
                <td>: {{ data.temp_lahir_ibu }}, {{ data.tgl_lahir_ibu |moment("DD-MMMM-YYYY")}}</td>
              </tr>
              <tr>
                <td>Pendidikan Terakhir</td>
                <td>: {{ data.pend_ibu }}</td>
              </tr>
              <tr>
                <td>Pekerjaan</td>
                <td>: {{ data.kerja_ibu }}</td>
              </tr>
              <tr>
                <td>Kewarganegaraan</td>
                <td>: {{ data.nation_ibu }}</td>
              </tr>
              <tr>
                <td>No Telp./HP</td>
                <td>: {{ data.no_hp_ibu }}</td>
              </tr>
            </table>
            <br/>

            <table>
              <tr>
                <td>Email Aktif</td>
                <td>: {{ data.email_ortu }}</td>
              </tr>
              <tr>
                <td>Pendapatan Per Bulan</td>
                <td>: {{ data.pendapatan }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- pecah disini -->

      <div class="page " ref="content3">
        <div :class="blur">
          <div class="text-bold"  style="text-decoration: underline;">
            IDENTITAS WALI
          </div>
          <div>
            <table>
              <tr>
                <td>Nama</td>
                <td>: {{ data.nama_wali }}</td>
              </tr>
              <tr>
                <td>Tempat &amp; Tanggal Lahir</td>
                <td>: {{ data.temp_lahir_wali }}, {{ data.tgl_lahir_wali  |moment("DD-MMMM-YYYY")}}</td>
              </tr>
              <tr>
                <td>Pendidikan Terakhir</td>
                <td>: {{ data.pend_wali }}</td>
              </tr>
              <tr>
                <td>Pekerjaan</td>
                <td>: {{ data.kerja_wali }}</td>
              </tr>
              <tr>
                <td>Kewarganegaraan</td>
                <td>: {{ data.nation_wali }}</td>
              </tr>
              <tr>
                <td>No Telp./HP</td>
                <td>: {{ data.no_hp_wali }}</td>
              </tr>
              <tr>
                <td>Alamat</td>
                <td>: {{ data.alamat_wali }}</td>
              </tr>
              <tr>
                <td>Kode POS</td>
                <td>: {{ data.pos_wali }}</td>
              </tr>
            </table>
          </div>
          <br />
          <div class="text-bold q-mt-md"  style="text-decoration: underline;">
            DATA PERIODIK SISWA/I
          </div>
          <div>
            <table>
              <tr>
                <td>Golongan Darah</td>
                <td>: {{ data.gol_darah }}</td>
              </tr>
              <tr>
                <td>Tinggi Badan</td>
                <td>: {{ data.tinggi_badan }} cm</td>
              </tr>
              <tr>
                <td>Berat Badan</td>
                <td>: {{ data.berat_badan }} Kg</td>
              </tr>
              <tr>
                <td>Penyakit yang Pernah Diderita</td>
                <td>: {{ data.riwayat_penyakit }}</td>
              </tr>
              <tr>
                <td>Kelainan Jasmani Lainnya</td>
                <td>: {{ data.kelainan }}</td>
              </tr>
            </table>
          </div>
          <br />
          <div class="text-bold q-mt-md"  style="text-decoration: underline;">
            DATA PENDIDIKAN SEBELUMNYA
          </div>
          <div>
            <table>
              <tr>
                <td>Nama Sekolah</td>
                <td>: {{ data.asal_sekolah }}</td>
              </tr>
              <tr>
                <td>Alamat Sekolah</td>
                <td>: {{ data.alamat_sekolah_asal }}</td>
              </tr>
              <tr>
                <td>NISN</td>
                <td>: {{ data.nisn }}</td>
              </tr>
              <tr>
                <td>No Ijazah/STTB</td>
                <td>: {{ data.no_ijazah }}</td>
              </tr>
            </table>
          </div>
          <br />
          <div class="text-bold q-my-sm"  style="text-decoration: underline;">
            PRESTASI SISWA/I
          </div>
          <div>
            <table class="prestasi">
              <tr>
                <td style="width: 20px;">No</td>
                <td>Prestasi</td>
                <td>Bidang</td>
                <td>Tahun</td>
                <td>Instansi</td>
              </tr>
              <tr v-for="(x, i) in prestasi" :key="i">
                <td style="width: 20px;">{{ i + 1 }}</td>
                <td>{{ x.nama_prestasi }}</td>
                <td>{{ x.bidang }}</td>
                <td>{{ x.tahun }}</td>
                <td>{{ x.instansi }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="q-pa-xl" :class="blur">
          <div class="text-right q-mb-md">Tangerang, {{ date | moment("DD MMMM YYYY") }}</div>
          <div class="row justify-between">
            <div class="text-center">
              Orang Tua/Wali <br /><br /><br /><br /><br /><br /><br /><br />
              (..........................................)
            </div>
            <div class="text-center">
              Calon Siswa/i <br /><br /><br /><br /><br /><br /><br /><br />
              (..........................................)
            </div>
          </div>
        </div>
      </div>
      <q-btn
        v-if="!blur"
        color="light-blue-3"
        class="printButton fixed-center"
        style="z-index: 1000;"
        label="Download PDF"
        @click="print"
      />
    </div>
    <div class="fixed-center" style="width: 300px;" v-if="alert">
      <q-card class="bg-amber-7 text-white  text-subtitle1 text-center">
        <q-card-section>
          Anda belum bisa buka dan download formulir <br/>
          Segera lakukan pembayaran biaya pendaftaran sesuai petunjuk dalam email
        </q-card-section>
      </q-card>
    </div>
    <div class="fixed-center" style="width: 300px;" v-if="alert2">
      <q-card class="bg-amber-7 text-white  text-subtitle1 text-center">
        <q-card-section>
          Pembayaran anda sedang dalam proses verifikasi <br/>
          Verifikasi pembayaran maksimal 1 x 24 jam
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<style></style>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "FormData",
  data() {
    return {
      nisn: "",
      data: {},
      prestasi: [],
      date: new Date(),
      blur: 'blur',
      alert: false, alert2: false,
      check: false
    };
  },
  mounted() {
    this.nisn = this.$route.params.nisn;

    this.$http.get("/pendaftar_bynisn/" + this.nisn, {}).then(async (result) => {
      this.data = result.data;
      
      if (result.data.status == 'Y'){
        this.blur = ''
      }
      else if (!result.data.file_transfer){
        this.alert = true
      }
      else{
        this.alert2 = true
      }

      this.$http.get("/prestasi_pendaftar/" + result.data.id, {}).then(async (result) => {
        this.prestasi = result.data;
      });
    });

  },
  methods: {
    async print() {
      var filename = "Data Siswa " + this.data.nama + ".pdf"; // this.coa.date_out.substr(0,10) + "_" + this.coa.nama_sampel + "_" + this.coa.batch +".pdf"

      const doc = await new jsPDF("p", "mm", "a4");
      /** WITH CSS */
      // var canvasElement = document.createElement("canvas");
      await html2canvas(this.$refs.content1, {scale:1.2}).then((canvas)=>{
        const img = canvas.toDataURL("image/png");
        doc.addImage(img, "JPEG", 0, 0, 210, 297, "alias1", "MEDIUM");
      });
      // var canvasElement2 = document.createElement("canvas2");
      await html2canvas(this.$refs.content2, {scale:1.2}).then((canvas2)=>{
        doc.addPage()
        const img2 = canvas2.toDataURL("image/png");
        doc.addImage(img2, "JPEG", 0, 0, 210, 297, "alias2", "MEDIUM");
      });
      // var canvasElement3 = document.createElement("canvas3");
      await html2canvas(this.$refs.content3, {scale:1.2}).then((canvas3)=>{
        doc.addPage()
        const img3 = canvas3.toDataURL("image/png");
        doc.addImage(img3, "JPEG", 0, 0, 210, 297, "alias2", "MEDIUM");
        doc.save(filename);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 210mm;
  height: 297mm;
  // padding-bottom: 30px;
  padding: 22mm 22mm 22mm 30mm;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  // overflow: auto;
  line-height: 1;
}
table {
  td:nth-child(1) {
    width: 200px;
  }
  tr,
  td {
    padding: 5px;
  }
}
.prestasi {
  border-collapse: collapse;
  width: 100%;
  // border: 1px solid black ;
  tr,
  td {
    border: 1px solid black;
  }
}

.printButton {
  top: 40px;
}
.blur{
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
.kelengkapan{
  td:nth-child(1) {
    width: 40px;
    // border: 1px solid black;
    text-align: left;
    display: flex; justify-content: end;
  }
  tr,
  td {
    padding: 5px;
    font-size: 18px;
  }
}
.box{
  width: 22px; height: 22px; border: 1px solid black;
}
</style>
